import React from "react";
import HeroDetail from "./HeroDetail";
import StickySocialLinks from "./StickySocialLinks";

const Hero = () => {
  return (
    <>
      <div className="hero-screen">
        {/* Siicky Social Media Links  */}
        <StickySocialLinks />

        {/* Detail In Hero Section And CTA Button */}
        <HeroDetail />

        {/* Mobile Social Links */}
        {/* <SmallSocialLinks /> */}
      </div>
    </>
  );
};

export default Hero;
