import React from "react";
import LmisSmallImage from "../../../assets/images/Portfolio Image/Lmis Card.png";
import LucySmallImage from "../../../assets/images/Portfolio Image/Lucy Card.png";
import AdiuSmallImage from "../../../assets/images/Portfolio Image/Adiu Card.png";
import LaborSmallImage from "../../../assets/images/Portfolio Image/Dashboard Card.png";
import SomunSmallImage from "../../../assets/images/Portfolio Image/Somun Card.png";
import EmdmsAgency from "../../../assets/images/Portfolio Image/EMDMS Card.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);
const Projects = ({ custom }) => {
  return (
    <>
      <div
        className={`${custom} flex flex-col p-8 gap-2 items-start md:items-center`}
      >
        <p className="font-primary  text-4xl md:text-6xl font-semibold">
          Projects
        </p>
        <p className="text-text-color/20">
          Some of the projects I have worked on as a UI/UX Designer and Frontend
          Developer.
        </p>
      </div>
      <div className="w-full grid md:grid-cols-2 gap-8 md:gap-14 px-6 md:px-28">
        <Link to={"/project/lmis-ethiopia"}>
          <img id="projectImage" src={LmisSmallImage} alt="LmisSmallImage" />
        </Link>
        <Link to={"/project/lucy-ecommerce"}>
          <img id="projectImage" src={LucySmallImage} alt="LucySmallImage" />
        </Link>
        <Link to={"/project/somun-ui-ux"}>
          <img id="projectImage" src={SomunSmallImage} alt="SomunSmallImage" />
        </Link>
        <Link to={"/project/adiu-communication"}>
          <img id="projectImage" src={AdiuSmallImage} alt="AdiuSmallImage" />
        </Link>
        <Link to={"/project/eow-agency"}>
          <img id="projectImage" src={EmdmsAgency} alt="LucySmallImage" />
        </Link>

        <img id="projectImage" src={LaborSmallImage} alt="LaborSmallImage" />
      </div>
    </>
  );
};

export default Projects;
