import React from "react";

const About = ({ custom }) => {
  return (
    <>
      <div className="md:h-screen w-full flex gap-8 md:gap-16 flex-col justify-center md:items-center p-8 py-10 md:py-0">
        <div
          className={`${custom} flex flex-col gap-2 items-start md:items-center`}
        >
          <p className="font-primary text-4xl md:text-6xl font-semibold">
            About Me
          </p>
          <p className="text-text-color/20">
            A short story about who I am What I love doing.
          </p>
        </div>
        <p className="md:w-4/5 text-lg sm:text-2xl lg:text-4xl font-extralight lg:leading-loose text-center">
          Hello, I am Zekarias Berhe UI/UX Designer and Front-end Web Developer
          based in Addis Ababa, Ethiopia, with more than 3 years of experience
          designing and developing things for the web. Within my 3+ years of
          professional experience I had the opportunity to work on various
          projects of different scale.
        </p>
      </div>
    </>
  );
};

export default About;
