import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Button from "../../utilities/Button";
import MailSent from "../../../assets/MailSent.gif";
import MainBackground from "../../../assets/MainBackground.svg";

const Contact = () => {
  const myForm = useRef();
  const [emailSuccess, setEmailSuccess] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yaf91zr",
        "template_bbhsxbf",
        myForm.current,
        "LlZIZiwUrWpczVGee"
      )
      .then(
        (result) => {
          setEmailSuccess("success");
          setEmailSuccess(true);
        },
        (error) => {
          setEmailSuccess("fail");
        }
      );
  };

  return (
    <>
      <div className="md:h-screen w-full flex gap-8 md:gap-16 flex-col justify-center md:items-center p-8 py-16 md:py-0">
        <div className="flex flex-col gap-2 items-start md:items-center">
          <p className="font-primary text-4xl md:text-6xl font-semibold">
            Contact Me
          </p>
          <p className="text-text-color/20">
            Reach ot for an new project or just say hi!
          </p>
        </div>
        <div className=" w-full sm:w-4/5 flex gap-20 justify-center items-center">
          {/* <div className="px-6 py-9 md:flex justify-center items-center relative bg-text-color group hidden">
            <div className="h-full w-full bg-text-color/60 absolute top-0 group-hover:opacity-50 transition-all duration-300 ease-in-out"></div>
            <img src={MyImage} alt="myImage" className="h-[20rem]" />
          </div> */}

          <form
            ref={myForm}
            onSubmit={sendEmail}
            className="w-full md:w-1/2 flex flex-col gap-6"
          >
            <div>
              <input
                type="text"
                name="from_name"
                className="w-full py-3 bg-transparent border px-2 text-text-color border-text-color focus:border-2 focus:outline-none placeholder:text-text-color/50"
                placeholder="Full Name"
                required
              />
            </div>
            <div>
              <input
                type="email"
                name="from_email"
                className="w-full py-3 bg-transparent border px-2 text-text-color border-text-color focus:border-2 focus:outline-none placeholder:text-text-color/50"
                placeholder="Email Address"
                required
              />
            </div>

            <div>
              <textarea
                name="message"
                className="w-full py-3 bg-transparent border px-2 text-text-color border-text-color focus:border-2 focus:outline-none placeholder:text-text-color/50 resize-none h-[14rem]"
                placeholder="Message"
              ></textarea>
            </div>
            <div>
              <Button type={"submit"} custom={"w-full"}>
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>

      {emailSuccess && (
        <>
          <div
            id="popup-modal"
            tabindex="-1"
            class="fixed top-0 right-0 left-0 z-50 bg-text-color/30 backdrop-blur-[1px] flex justify-center items-center w-full md:inset-0 h-screen max-h-full"
          >
            {" "}
            <div class="relative bg-primary rounded-lg shadow-xl overflow-hidden">
              {" "}
              <img
                src={MainBackground}
                alt="MainBackground"
                className="absolute top-0 left-0 w-full blur-[120px]"
              />
              <div class="relative p-4 w-full max-w-md max-h-full z-20">
                <button
                  onClick={() => {
                    setEmailSuccess(false);
                  }}
                  type="button"
                  class="absolute top-6 right-6 end-2.5 text-gray-400 bg-transparent rounded-full text-sm ms-auto inline-flex justify-center items-center"
                >
                  <svg
                    class="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
                <div class="p-4 md:p-5 text-center flex justify-center items-center flex-col ">
                  <img src={MailSent} alt="Mail Sent" />
                  <h1 class="mb-5 text-xl text-text-color font-semibold">
                    Message Sent Successfully
                  </h1>
                  <h3 class="mb-5 font-normal text-text-color">
                    Thank you for contacting me! I'll get back to you as soon as
                    possible.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Contact;
