import React, { useEffect, useState } from "react";
import webProject from "../../Projects/webProjects";
import { Link, useParams } from "react-router-dom";
import {
  AiOutlineBehance,
  AiOutlineGithub,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";

const WebProject = () => {
  const { id } = useParams();
  const [project, setProject] = useState();

  useEffect(() => {
    const foundProject = webProject.find((item) => item.id === id);
    setProject(foundProject);
  }, [id]);
  return (
    <>
      {project && (
        <div className="min-h-screen flex flex-col px-4 sm:px-10 md:px-20 gap-10 md:gap-20 text-[#252323]">
          <img
            src={project.banner}
            alt="Project Banner"
            className="w-full mt-10  object-cover rounded-3xl"
          />
          <p className="text-sm sm:text-xl lg:text-3xl font-extralight lg:leading-loose">
            {project.description}
          </p>

          <div className="flex flex-col">
            <h1 className="text-sm sm:text-xl lg:text-3xl  font-bold font-space">
              My Role
            </h1>
            <div className="grid grid-cols-2 gap-6">
              {project.roles.map((item, index) => {
                return (
                  <p
                    key={index}
                    className="text-sm sm:text-xl lg:text-3xl  font-extralight mt-6 lg:leading-loose"
                  >
                    {item.roleName}
                  </p>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col">
            <h1 className="text-sm sm:text-xl lg:text-3xl  font-bold font-space">
              Tools & Tech Stack
            </h1>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-8">
              {project.tools.map((item, index) => {
                return (
                  <p
                    key={index}
                    className="text-sm sm:text-xl lg:text-3xl  font-extralight mt-6 lg:leading-loose"
                  >
                    {item.toolName}
                  </p>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-10">
            <h1 className="text-sm sm:text-xl lg:text-3xl  font-bold font-space">
              Preview
            </h1>
            <div className="flex flex-col justify-center items-center gap-16 lg:gap-32">
              {project.mockupImages.map((item, index) => {
                return (
                  <>
                    <img
                      key={index}
                      src={item.mockupImage}
                      alt="Lmis mockupImage"
                      className="w-full"
                    />
                  </>
                );
              })}

              <div className="flex gap-2 items-center border-b border-text-color">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.1647 10.3623C16.9219 11.643 16.9375 11.6396 16.6349 12.3524C15.9952 13.8596 14.9016 15.1299 13.5063 15.9864C12.1109 16.843 10.4832 17.2433 8.84969 17.1315C7.2162 17.0198 5.65818 16.4016 4.39246 15.363C3.12674 14.3243 2.21629 12.917 1.78783 11.3367C1.35937 9.75645 1.43421 8.08193 2.00193 6.5462C2.56966 5.01047 3.60202 3.68992 4.95534 2.76834C6.30867 1.84677 7.91563 1.37001 9.55258 1.40443"
                    stroke="black"
                    stroke-linecap="round"
                  />
                  <path
                    d="M7.36057 10.832C7.15766 11.0193 7.145 11.3356 7.33231 11.5385C7.51961 11.7414 7.83594 11.7541 8.03885 11.5668L7.36057 10.832ZM17.2988 2.81979C17.3099 2.54387 17.0951 2.31124 16.8192 2.3002L12.3228 2.12035C12.0469 2.10931 11.8143 2.32404 11.8032 2.59996C11.7922 2.87589 12.0069 3.10851 12.2828 3.11955L16.2796 3.27942L16.1198 7.27623C16.1087 7.55215 16.3235 7.78477 16.5994 7.79581C16.8753 7.80685 17.1079 7.59211 17.119 7.31619L17.2988 2.81979ZM8.03885 11.5668L17.1384 3.16721L16.4601 2.4324L7.36057 10.832L8.03885 11.5668Z"
                    fill="black"
                  />
                </svg>
                <a
                  href={project.link}
                  rel="noreferrer"
                  target="_blank"
                  className="font-extralight text-lg"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="m-10 md:m-20 gap-10 flex justify-center items-center p-10 flex-col bg-[#D9D9D9]/20 text-text-color">
        <div className="flex gap-8">
          <Link target="_blank" to="https://www.instagram.com/zack_berhe/">
            <AiOutlineInstagram className="cursor-pointer transition-all duration-300 ease-in hover:rotate-12 text-4xl opacity-60 hover:opacity-100" />
          </Link>
          <Link target="_blank" to="https://www.behance.net/zekariasberhe1">
            <AiOutlineBehance className="cursor-pointer transition-all duration-300 ease-in hover:rotate-12 text-4xl opacity-60 hover:opacity-100" />
          </Link>
          <Link
            target="_blank"
            to="https://www.linkedin.com/in/zekarias-berhe-103549247/"
          >
            <AiOutlineLinkedin className="cursor-pointer transition-all duration-300 ease-in hover:rotate-12 text-4xl opacity-60 hover:opacity-100" />
          </Link>
          <Link target="_blank" to="https://github.com/ZackyBerhe">
            <AiOutlineGithub className="cursor-pointer transition-all duration-300 ease-in hover:rotate-12 text-4xl opacity-60 hover:opacity-100" />
          </Link>
        </div>
        <p className="text-2xl text-light opacity-60">+251 944 773 922</p>
      </div>
    </>
  );
};

export default WebProject;
