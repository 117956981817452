import React from "react";

const Button = ({ type, children, custom }) => {
  return (
    <>
      <button
        type={type}
        className={`${custom} py-2 px-8 bg-text-color text-white hover:bg-text-color/80 cursor-pointer`}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
