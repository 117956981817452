import React from "react";

const Footer = () => {
  return (
    <>
      <div className="w-full flex items-center justify-center gap-6">
        <p className="md:text-xl text-text-color">
          developer@zekariasberhe.dev
        </p>
      </div>
    </>
  );
};

export default Footer;
