import React from "react";
import ZackLogo from "../../../assets/Logo.svg";

const NavigationBar = () => {
  return (
    <>
      <div className="px-10 md:px-20 flex justify-between items-center w-full">
        <img
          src={ZackLogo}
          alt="ZackLogo"
          className="w-32 hover:rotate-2 transition-transform ease-in-out duration-500 cursor-pointer"
        />

        <ul className="flex justify-between gap-16">
          <a
            href="/"
            className="pb-0.5 cursor-pointer transition-all duration-200 ease-in relative group"
          >
            Home
            <div className="h-0.5 w-0 group-hover:w-8 transition-all duration-200 ease-in-out bg-text-color"></div>
          </a>

          {/* <li
            onClick={() => {
              scrollToSection("Skills");
            }}
            className="pb-0.5 cursor-pointer transition-all duration-200 ease-in relative group"
          >
            Skills
            <div className="h-0.5 w-0 group-hover:w-8 transition-all duration-200 ease-in-out bg-text-color"></div>
          </li>

          <li
            onClick={() => {
              scrollToSection("Projects");
            }}
            className="pb-0.5 cursor-pointer transition-all duration-200 ease-in relative group"
          >
            Projects
            <div className="h-0.5 w-0 group-hover:w-8 transition-all duration-200 ease-in-out bg-text-color"></div>
          </li>
          <li
            onClick={() => {
              scrollToSection("About");
            }}
            className="pb-0.5 cursor-pointer transition-all duration-200 ease-in relative group"
          >
            About
            <div className="h-0.5 w-0 group-hover:w-8 transition-all duration-200 ease-in-out bg-text-color"></div>
          </li>

          <li
            onClick={() => {
              scrollToSection("Contact");
            }}
            className="pb-0.5 cursor-pointer transition-all duration-200 ease-in relative group"
          >
            Contact
            <div className="h-0.5 w-0 group-hover:w-8 transition-all duration-200 ease-in-out bg-text-color"></div>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default NavigationBar;
