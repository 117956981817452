import React from "react";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import NavigationBar from "./components/singleComponents/landing/NavigationBar";
import PageNotFound from "./pages/PageNotFound";
// import MainBackground from "./assets/MainBackground.svg";
import Lenis from "@studio-freight/lenis";
import WebProject from "./pages/WebProject/[id]";

const App = () => {
  const lenis = new Lenis({
    lerp: 0.08,
  });

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
  return (
    <>
      <div className="py-10 md:py-20 text-text-color relative z-30">
        <NavigationBar />
        <Routes scrollRestoration="auto">
          <Route path="/" element={<Home />} />
          <Route path="project/:id" element={<WebProject />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </div>
      {/* <img
        src={MainBackground}
        alt="MainBackground"
        className="fixed object-cover top-0 left-0 h-screen blur-[130px] lg:blur-[200px] w-screen opacity-40 z-10"
      /> */}
    </>
  );
};

export default App;
