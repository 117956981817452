import React from "react";
import About from "../components/section/landing/About";
import Contact from "../components/section/landing/Contact";
import Hero from "../components/section/landing/Hero";
import Projects from "../components/section/landing/Projects";
import SkillsSection from "../components/section/landing/SkillsSection";

const Home = () => {
  return (
    <>
      <div className="flex flex-col px-10 md:px-20 md:gap-20">
        <Hero id="Home" />
        <SkillsSection id="Skills" />
        <Projects id="Projects" />
        <About id="About" />
        <Contact id="Contact" />
      </div>
    </>
  );
};

export default Home;
