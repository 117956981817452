import React, { useEffect } from "react";
import gsap from "gsap";
import { IoMdDownload } from "react-icons/io";

const HeroDetail = () => {
  useEffect(() => {
    // For Title
    let MainTextAnnimation = gsap.timeline({
      defaults: { ease: "SlowMo.easeOut" },
    });

    MainTextAnnimation.to("#myTitle", { y: "0%", duration: 1, stagger: 0.5 });

    gsap.to(".descriptionText", {
      y: "0%",
      stagger: 0.5,
      delay: 0.05,
      duration: 1,
    });
    // console.log(description);
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col items-start justify-center gap-8">
        <div className="flex flex-col items-end gap-0">
          <p className="text overflow-hidden font-primary text-6xl xl:text-8xl w-full">
            <span
              id="myTitle"
              className="inline-block translate-y-full w-full sm:whitespace-nowrap pb-2"
            >
              UI Designer
            </span>
          </p>
          <p className="text overflow-hidden font-primary text-6xl xl:text-8xl w-full">
            <span
              id="myTitle"
              className="inline-block translate-y-full w-full sm:whitespace-nowrap pb-2"
            >
              Front-End Developer
            </span>
          </p>
        </div>

        <div className="flex flex-col gap-0">
          <p className="font-secondary md:text-2xl font-light overflow-hidden ">
            <span className="descriptionText translate-y-full inline-block">
              Hello! I am <b className="font-bold">Zekarias Berhe</b> I am a
              creative and passionate UI Designer
            </span>
          </p>
          <p className="font-secondary md:text-2xl font-light overflow-hidden ">
            <span className="descriptionText translate-y-full inline-block">
              and Frontend Web Developer based in Addis Ababa, Ethiopia.
            </span>
          </p>
        </div>

        <div className="flex gap-2 sm:gap-6 sm:flex-row flex-col items-start sm:items-start w-full">
          <button className="text-xs sm:texs-sm border-4 border-text-color bg-text-color text-primary hover:bg-black w-full sm:w-max py-2 px-12 transition-all duration-300 ease-in-out">
            Contact Me
          </button>
          <a
            href="Zekarias Berhe Resume.pdf"
            download="Zekarias's Resume.pdf"
            className="text-xs sm:texs-sm cursor-pointer border-2 border-text-color text-text-color hover:bg-text-color hover:text-primary w-full sm:w-max py-2 px-12 flex items-center justify-center gap-2 transition-all duration-300 ease-in-out"
          >
            <IoMdDownload className="text-base sm:text-xl" />
            Resume
          </a>
        </div>
      </div>
    </>
  );
};

export default HeroDetail;
