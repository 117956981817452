import React from "react";
import PageNotFoundSvg from "../assets/images/pagenotfound.svg";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full py-10 flex flex-col justify-center items-center gap-4">
        <img src={PageNotFoundSvg} alt="PageNotFoundSvg" className="h-[70vh]" />
        <p
          onClick={() => {
            navigate(-1);
          }}
          className="text-2xl text-text-color cursor-pointer"
        >
          Go Back
        </p>
      </div>
    </>
  );
};

export default PageNotFound;
