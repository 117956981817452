import React from "react";
import {
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineGithub,
  AiOutlineBehance,
} from "react-icons/ai";
import { Link } from "react-router-dom";

const StickySocialLinks = () => {
  return (
    <>
      <div className="h-[80vh] fixed right-3 md:right-10 flex flex-col justify-around items-center text-text-color text-2xl">
        <div className="h-1/4 rounded-full "></div>
        <Link target="_blank" to="https://www.instagram.com/zack_berhe/">
          <AiOutlineInstagram className="cursor-pointer transition-all duration-300 ease-in hover:rotate-45" />
        </Link>
        <Link target="_blank" to="https://www.behance.net/zekariasberhe1">
          <AiOutlineBehance className="cursor-pointer transition-all duration-300 ease-in hover:rotate-45" />
        </Link>
        <Link
          target="_blank"
          to="https://www.linkedin.com/in/zekarias-berhe-103549247/"
        >
          <AiOutlineLinkedin className="cursor-pointer transition-all duration-300 ease-in hover:rotate-45" />
        </Link>
        <Link target="_blank" to="https://github.com/ZackyBerhe">
          <AiOutlineGithub className="cursor-pointer transition-all duration-300 ease-in hover:rotate-45" />
        </Link>
        <div className="h-1/4 rounded-full "></div>
      </div>
    </>
  );
};

export default StickySocialLinks;
