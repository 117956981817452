import React, { useEffect, useRef } from "react";

// Logos for list of Skills
import ReactLogo from "../../../assets/logos/icons8-react-native-480.png";
import Apollo from "../../../assets/logos/icons8-apollo-48.png";
import Graphql from "../../../assets/logos/icons8-graphql-50.png";
import JavascriptLogo from "../../../assets/logos/icons8-javascript-480.png";
import AdobeXdLogo from "../../../assets/logos/icons8-adobe-xd-480.png";
import FigmaLogo from "../../../assets/logos/icons8-figma-480.png";
import TailwindcssLogo from "../../../assets/logos/icons8-tailwindcss-480.png";
import VueLogo from "../../../assets/logos/icons8-vue-js-480.png";
import GithubLogo from "../../../assets/logos/icons8-github-480.png";
import ScssLogo from "../../../assets/logos/icons8-sass-50.png";
import GsapLogo from "../../../assets/logos/gsap-greensock 1.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const SkillsSection = () => {
  const reactRef = useRef();
  const htmlRef = useRef();
  const cssRef = useRef();
  const javascriptRef = useRef();
  const adobeRef = useRef();
  const figmaRef = useRef();
  const tailwindcssRef = useRef();
  const vueRef = useRef();
  const gitRef = useRef();
  const scssRef = useRef();
  const gsapRef = useRef();

  useEffect(() => {
    const reactEl = reactRef.current;
    const htmlEl = htmlRef.current;
    const cssEl = cssRef.current;
    const javascriptEl = javascriptRef.current;
    const adobeEl = adobeRef.current;
    const figmaEl = figmaRef.current;
    const tailwindcssEl = tailwindcssRef.current;
    const vueEl = vueRef.current;
    const gitEl = gitRef.current;
    const scssEl = scssRef.current;
    const gsapEl = gsapRef.current;

    gsap.fromTo(
      reactEl,
      { y: 60 },
      {
        y: 0,
        duration: 0.6,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      htmlEl,
      { y: 60 },
      {
        y: 0,
        duration: 1,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      cssEl,
      { y: 60 },
      {
        y: 0,
        duration: 1.4,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      javascriptEl,
      { y: 60 },
      {
        y: 0,
        duration: 1.8,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      adobeEl,
      { y: 60 },
      {
        y: 0,
        duration: 2.2,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      figmaEl,
      { y: 60 },
      {
        y: 0,
        duration: 2.6,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      tailwindcssEl,
      { y: 60 },
      {
        y: 0,
        duration: 3,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      vueEl,
      { y: 60 },
      {
        y: 0,
        duration: 3.4,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      gitEl,
      { y: 60 },
      {
        y: 0,
        duration: 3.8,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      scssEl,
      { y: 60 },
      {
        y: 0,
        duration: 4.2,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
    gsap.fromTo(
      gsapEl,
      { y: 60 },
      {
        y: 0,
        duration: 4.6,
        ScrollTrigger: {
          trigger: reactEl,
        },
      }
    );
  }, []);

  return (
    <>
      <div className="h-screen w-full flex gap-8 p-8 flex-col justify-center md:justify-around items-center">
        <div className="flex flex-col gap-2 items-start md:items-center">
          <p className="font-primary text-4xl md:text-6xl font-semibold">
            Skills
          </p>
          <p className="text-text-color/20">
            Some of the skills I developens while working for organizations and
            companies
          </p>
        </div>
        <div className="w-full md:grid gap-6 md:grid-cols-4 text-center text-text-color text-3xl hidden">
          <div ref={reactRef} className="relative font-medium">
            <p>React .Js</p>
            <img
              src={ReactLogo}
              alt="reactLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
          <div ref={htmlRef} className="relative font-medium">
            <p>Apollo</p>
            <img
              src={Apollo}
              alt="ApolloLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
          <div ref={cssRef} className="relative font-medium">
            <p>Graphql</p>
            <img
              src={Graphql}
              alt="GraphqlLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
          <div ref={javascriptRef} className="relative font-medium">
            <p>Javascript</p>
            <img
              src={JavascriptLogo}
              alt="JavascriptLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
        </div>
        <div className="w-full md:grid gap-6 md:grid-cols-3 text-center text-text-color text-3xl hidden">
          <div ref={adobeRef} className="relative font-medium">
            <p>Adobe XD</p>
            <img
              src={AdobeXdLogo}
              alt="AdobeXdLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
          <div ref={figmaRef} className="relative font-medium">
            <p>Figma</p>
            <img
              src={FigmaLogo}
              alt="FigmaLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
          <div ref={tailwindcssRef} className="relative font-medium">
            <p>Tailwindcss</p>
            <img
              src={TailwindcssLogo}
              alt="TailwindcssLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
        </div>
        <div className="w-full md:grid gap-6 md:grid-cols-4 text-center text-text-color text-3xl hidden">
          <div ref={vueRef} className="relative font-medium">
            <p>Vue .Js</p>
            <img
              src={VueLogo}
              alt="VueLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
          <div ref={gitRef} className="relative font-medium">
            <p>Git</p>
            <img
              src={GithubLogo}
              alt="GithubLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
          <div ref={scssRef} className="relative font-medium">
            <p>scss</p>
            <img
              src={ScssLogo}
              alt="ScssLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
          <div ref={gsapRef} className="relative font-medium">
            <p>GSAP</p>
            <img
              src={GsapLogo}
              alt="GsapLogo"
              className="absolute h-[100px] w-[100px] opacity-5 -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 md:block hidden"
            />
          </div>
        </div>

        <div className="flex gap-6 flex-wrap text-xl font-medium md:hidden">
          <p>React .Js</p>
          <p>HTML5</p>
          <p>CSS3</p>
          <p>Javascript</p>
          <p>Adobe XD</p>
          <p>Figma</p>
          <p>Tailwindcss</p>
          <p>Vue .Js</p>
          <p>Git</p>
          <p>scss</p>
          <p>GSAP</p>
        </div>
      </div>
    </>
  );
};

export default SkillsSection;
