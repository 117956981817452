import LmisBanner from "../assets/Projects/banner/Lmis Banner.png";
import EmdmsBanner from "../assets/Projects/banner/EMDMS Banner.png";
import LucyBanner from "../assets/Projects/banner/Lucy Banner.png";
import AdiuBanner from "../assets/Projects/banner/Adiu Banner.png";
import SomunBanner from "../assets/Projects/banner/Somun Banner.png";
import LmisMockupOne from "../assets/Projects/mockup/Lmis Mockup One.png";
import LmisMockupTwo from "../assets/Projects/mockup/Lmis Mockup Two.png";
import LmisMockupThree from "../assets/Projects/mockup/Lmis Mockup Three.png";
import EmdmsMockupOne from "../assets/Projects/mockup/EMDMS Mockup One.png";
import EmdmsMockupTwo from "../assets/Projects/mockup/EMDMS Mockup Two.png";
import EmdmsMockupThree from "../assets/Projects/mockup/EMDMS Mockup Three.png";
import LucyMockupOne from "../assets/Projects/mockup/Lucy Mockup One.png";
import LucyMockupTwo from "../assets/Projects/mockup/Lucy Mockup Two.png";
import LucyMockupThree from "../assets/Projects/mockup/Lucy Mockup Three.png";
import AdiuMockupOne from "../assets/Projects/mockup/Adiu Mockup One.png";
import AdiuMockupTwo from "../assets/Projects/mockup/Adiu Mockup Two.png";
import AdiuMockupThree from "../assets/Projects/mockup/Adiu Mockup Three.png";
import SomunMockupOne from "../assets/Projects/mockup/Somun Mockup One.png";
import SomunMockupTwo from "../assets/Projects/mockup/Somun Mockup Two.png";
import SomunMockupThree from "../assets/Projects/mockup/Somun Mockup Three.png";

const webProject = [
  {
    id: "lmis-ethiopia",
    title: "Lmis Ethiopia",
    banner: LmisBanner,
    description:
      "The Ethiopian Labor Market Information System (E-LMIS) project aimed to alleviate joblessness among Ethiopian citizens by implementing a robust system for registering their biometric data. As a front-end developer, I played a crucial role in creating an intuitive user interface that facilitated the seamless capture and registration of biometric information. Leveraging my expertise in front-end technologies like React, HTML, CSS, and JavaScript, I collaborated closely with the design and back-end teams to ensure a smooth integration of biometric data capture and storage. By focusing on usability and data security, I contributed to the development of a user-friendly interface that empowered Ethiopian citizens to register their biometrics accurately, providing valuable labor market information to drive effective employment policies and initiatives.",
    roles: [{ roleName: "Front-End Developer" }],
    tools: [
      { toolName: "React.js" },
      { toolName: "Tailwindcss" },
      { toolName: "GSAP" },
      { toolName: "Apollo Client" },
    ],
    mockupImages: [
      { mockupImage: LmisMockupOne },
      { mockupImage: LmisMockupTwo },
      { mockupImage: LmisMockupThree },
    ],
    link: "https://lmis.gov.et/",
  },
  {
    id: "eow-agency",
    title: "Ethiopian Overseas Workers",
    banner: EmdmsBanner,
    description:
      "The Ethiopian Overseas Workers project aimed to solve the major problem faced by the Ethiopian labor force seeking job opportunities overseas. As a front-end developer, my role was pivotal in creating an intuitive and user-friendly interface using the latest tech stack. I collaborated closely with the design and back-end teams to integrate data, optimize performance, and ensure a seamless user experience. By leveraging my expertise in React, HTML, CSS, and JavaScript, I contributed to the development of a visually appealing platform that empowered Ethiopian job seekers to explore overseas opportunities efficiently.",
    roles: [{ roleName: "Front-End Developer" }],
    tools: [
      { toolName: "React.js" },
      { toolName: "Tailwindcss" },
      { toolName: "Apollo Client" },
    ],
    mockupImages: [
      { mockupImage: EmdmsMockupOne },
      { mockupImage: EmdmsMockupTwo },
      { mockupImage: EmdmsMockupThree },
    ],
    link: "https://eows.lmis.gov.et/",
  },
  {
    id: "lucy-ecommerce",
    title: "Lucy Gebeya(Market)",
    banner: LucyBanner,
    description:
      "The Lucy Gebey ecommerce project aimed to establish an online marketplace that connected small to large enterprises in Ethiopia with global customers, enabling them to sell and purchase products. As a frontend developer, my role was pivotal in creating an engaging and user-friendly interface. Using technologies like React, HTML, CSS, and JavaScript, I collaborated closely with the design and backend teams to design and implement responsive layouts, intuitive navigation, and seamless user interactions. By integrating frontend components with backend functionalities, I contributed to key features such as product listings, shopping cart management, and secure payment processing. Through iterative development and user feedback, I played a vital part in creating a robust ecommerce platform that empowered Ethiopian enterprises to showcase and expand their businesses on an international scale.",
    roles: [{ roleName: "Front-End Developer" }],
    tools: [
      { toolName: "React.js" },
      { toolName: "Tailwindcss" },
      { toolName: "GSAP" },
      { toolName: "Apollo Client" },
    ],
    mockupImages: [
      { mockupImage: LucyMockupOne },
      { mockupImage: LucyMockupTwo },
      { mockupImage: LucyMockupThree },
    ],
    link: "https://lucy.gov.et/",
  },
  {
    id: "somun-ui-ux",
    title: "Somun Photography",
    banner: SomunBanner,
    description:
      "This Project was a case study for an app that focuses on artists, specifically Photographers. It is a social app where different photographers can share and compete with Pictures they took. The app helps its users to showcase their photography Skills. Besides helping photographers the app also aims to help business owners and individuals to explore and use photographs for their personal use or for their business. Since the app has different users with different backgrounds, the UX was designed to be easy to use for any user. You can just post your pictures or you can choose to compete in the weekly(Somunawi) competition.",
    roles: [{ roleName: "UI/UX Designer" }, { roleName: "UX Researcher" }],
    tools: [
      { toolName: "Figma" },
      { toolName: "Adobe Illustrator" },
      { toolName: "Adobe Photoshop" },
    ],
    mockupImages: [
      { mockupImage: SomunMockupOne },
      { mockupImage: SomunMockupTwo },
      { mockupImage: SomunMockupThree },
    ],
    link: "https://www.behance.net/gallery/175582545/Somun-Photography-Social-App",
  },
  {
    id: "adiu-communication",
    title: "Adiu Communication Service",
    banner: AdiuBanner,
    description:
      "The Adiu Communication Service project was developed for a private company specializing in communication services, tower erection, and related solutions. As a frontend developer, my role was crucial in building the user interface of the project. Collaborating closely with the team, I utilized my skills in frontend technologies such as HTML, CSS, and JavaScript to create an intuitive and user-friendly interface. By implementing responsive designs, optimizing performance, and ensuring seamless navigation, I contributed to the development of a functional and visually appealing platform. Through my expertise in frontend development, I supported the company's objectives in providing effective communication services and tower erection solutions.",
    roles: [{ roleName: "Front-End Developer" }, { roleName: "UI Designer" }],
    tools: [
      { toolName: "React.js" },
      { toolName: "Tailwindcss" },
      { toolName: "GSAP" },
      { toolName: "Figma" },
    ],
    mockupImages: [
      { mockupImage: AdiuMockupOne },
      { mockupImage: AdiuMockupTwo },
      { mockupImage: AdiuMockupThree },
    ],
    link: "https://www.adiucommunication.com.et/",
  },
];

export default webProject;
